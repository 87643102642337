<template lang="html">
  <div class="LabsCounter">
      <div class="LabsCounter--Num">
        {{pointsNum}}
      </div>
      <div class="LabsCounter--Title">
        Labs
      </div>
  </div>
</template>

<script>
  export default {
    name: 'LabsCounter',

    props: {
      pointsNum : {
        type: Number,
        default: 0,
      },
    }
  }
</script>

<style scoped lang="scss">
  .LabsCounter {
    color: white;
    background: white;
    padding: 2px 1px 1px 1px;
    height: 58px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    &--Num{
      background: #f8b656;
      font-size: 21px;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      padding: 0 15px;
      margin-bottom:1px;
    }
    &--Title{
      font-size: 11px;
      height: 15px;
      font-weight: bold;
      text-align: center;
      background: #4f4f4f;
      text-transform: uppercase;
    }
  }
</style>
